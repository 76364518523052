import { ValidationError } from 'yup';

export default (t: (key: string) => string) => {
    const mixed = {
        default: t('FieldsValidations.field-required'),
        required: t(
            'FieldsValidations.field-required'
        ),
        defined: 'Musí být definováno',
        notNull: t(
            'FieldsValidations.field-required'
        ),
        oneOf: 'Musí být jedna z následujících hodnot: ${values}',
        notOneOf: 'Nesmí být jedna z následujících hodnot: ${values}',
        notType: ({ type }: any) => {
            return `Musí být typ \`${t('types.' + type)}\``
        },
    };

    const string = {
        length: 'Musí mít přesně ${length} znaků',
        min: 'Musí mít nejméně ${min} znaků',
        max: 'Musí mít maximálně ${max} znaků',
        matches: 'Musí odpovídat následujícímu: "${regex}"',
        email: t(
            'FieldsValidations.invalid-email'
        ),
        url: 'Musí být platná URL adresa',
        uuid: 'Musí být platné UUID',
        datetime: 'Musí být platné ISO datum a čas',
        datetime_precision:
            'Musí být platné ISO datum a čas s přesností subsekund na ${precision} číslic',
        datetime_offset:
            'Musí být platné ISO datum a čas s časovým posunem UTC "Z"',
        trim: 'Musí být oříznutý řetězec',
        lowercase: 'Musí být řetězec malými písmeny',
        uppercase: 'Musí být řetězec velkými písmeny',
    };

    const number = {
        min: 'Musí být větší nebo rovno ${min}',
        max: 'Musí být menší nebo rovno ${max}',
        lessThan: 'Musí být menší než ${less}',
        moreThan: 'Musí být větší než ${more}',
        positive: 'Musí být kladné číslo',
        negative: 'Musí být záporné číslo',
        integer: 'Musí být celé číslo',
    };

    const date = {
        min: 'Pole Musí být pozdější než ${min}',
        max: 'Pole Musí být dřívější než ${max}',
    };

    const boolean = {
        isValue: 'Pole Musí být ${value}',
    };

    const object = {
        noUnknown: 'Pole Má nespecifikované klíče: ${unknown}',
    };

    const array = {
        min: 'Pole Musí mít nejméně ${min} položek',
        max: 'Pole Musí mít maximálně ${max} položek',
        length: 'Musí mít ${length} položek',
    };

    const tuple = {
        notType: (params: any) => {
            const { path, value, spec } = params;
            const typeLen = spec.types.length;
            if (Array.isArray(value)) {
                if (value.length < typeLen)
                    return `Tuple hodnota má příliš málo položek, očekáváno délka ${typeLen} ale dostalo se ${
                        value.length
                    } pro hodnotu: \`${value}\``;
                if (value.length > typeLen)
                    return `Tuple hodnota má příliš mnoho položek, očekáváno délka ${typeLen} ale dostalo se ${
                        value.length
                    } pro hodnotu: \`${value}\``;
            }

            return ValidationError.formatError(mixed.notType, params);
        },
    };

    return {
        mixed,
        string,
        number,
        date,
        object,
        array,
        boolean,
        tuple,
    };
}
