import { dataTableRowActionsEnum } from "@/enums/dataTableRowActionsEnum";
import { useAcl } from "vue-simple-acl";
import { type usePermissionsInterface } from "@/interfaces/dataTable/usePermissionsInterface";
import { ModulesEnum } from "@/enums/ModulesEnum";

const acl = useAcl();

export function getGenericModulePermissions(moduleName: ModulesEnum): { [action in dataTableRowActionsEnum]?: string } {
    return {
        [dataTableRowActionsEnum.ROOT]: `${moduleName}`,
        [dataTableRowActionsEnum.FULL]: `${moduleName}-full`,
        [dataTableRowActionsEnum.DELETE]: `${moduleName}-delete`,
        [dataTableRowActionsEnum.DUPLICATE]: `${moduleName}-create`,
        [dataTableRowActionsEnum.DOWNLOAD]: `${moduleName}-read`,
        [dataTableRowActionsEnum.ARCHIVE]: `${moduleName}-archive`,
        [dataTableRowActionsEnum.UNARCHIVE]: `${moduleName}-archive`,
        [dataTableRowActionsEnum.EDIT]: `${moduleName}-update`,
        [dataTableRowActionsEnum.CREATE]: `${moduleName}-create`,
        [dataTableRowActionsEnum.READ]: `${moduleName}-read`,
    };
}

export function getGenericModulePermissionsList(moduleName: ModulesEnum): string[] {
    return [
        ...new Set(Object.values(getGenericModulePermissions(moduleName))),
    ];
}

export default function usePermissions(
    permissionActionKeys: { [action in dataTableRowActionsEnum]?: string | boolean },
): usePermissionsInterface {
    const can = (action: dataTableRowActionsEnum): boolean => {
        if (permissionActionKeys[action] === undefined) {
            return false;
        }

        if (typeof permissionActionKeys[action] === 'boolean') {
            return permissionActionKeys[action] as boolean; // boolean expected
        }

        return permissionActionKeys[action]
            ? acl.anyCan([
                permissionActionKeys[action] as string,  // string expected
                `${permissionActionKeys[dataTableRowActionsEnum.ROOT]}-full`
            ])
            : false;
    }

    return {
        canDelete: () => can(dataTableRowActionsEnum.DELETE),
        canDuplicate: () => can(dataTableRowActionsEnum.DUPLICATE),
        canDownload: () => can(dataTableRowActionsEnum.DOWNLOAD),
        canArchive: () => can(dataTableRowActionsEnum.ARCHIVE),
        canUnarchive: () => can(dataTableRowActionsEnum.UNARCHIVE),
        canEdit: () => can(dataTableRowActionsEnum.EDIT),
        canCreate: () => can(dataTableRowActionsEnum.CREATE),
        canRead: () => can(dataTableRowActionsEnum.READ),
    };
}
