import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from "@/composables/usePermissions";
import { ModulesEnum } from "@/enums/ModulesEnum";

const CarsRoutes = [
    {
        path: '/cars',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'GarageModule.name',
            icon: 'mdi-car',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: ':carId(\\d+|new)?',
                name: RouteNamesEnum.Cars,
                component: () => import('../views/garage-module/GarageView.vue'),
                meta: {
                    title: 'GarageModule.name',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Cars),
                    showInMenu: true,
                    isArchive: false,
                }
            },
            {
                path: 'archive/:id(\\d+|new)?',
                name: RouteNamesEnum.CarsArchive,
                component: () =>
                    import('../views/garage-module/GarageView.vue'),
                meta: {
                    title: 'GarageModule.archive',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Cars),
                    showInMenu: true,
                    isArchive: true,
                },
            },
            {
                path: 'files',
                name: RouteNamesEnum.CarModuleFiles,
                component: () =>
                    import('../views/garage-module/ModuleFilesView.vue'),
                meta: {
                    title: 'GarageModule.module-files',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Cars),
                    showInMenu: true,
                },
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'cars-categories/:id(\\d+|new)?',
                        name: RouteNamesEnum.CarsCategories,
                        component: () =>
                            import('../views/garage-module/CategoriesView.vue'),
                        meta: {
                            title: 'GarageModule.categories',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.CarCategories),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'services/:id(\\d+|new)?',
                        name: RouteNamesEnum.CarServices,
                        component: () =>
                            import('../views/garage-module/ServicesView.vue'),
                        meta: {
                            title: 'GarageModule.services',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.CarServices),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'car-basic-malfunctions/:id(\\d+|new)?',
                        name: RouteNamesEnum.CarBasicMalfunctions,
                        component: () => import('../views/settings-module/CarBasicMalfunctionsView.vue'),
                        meta: {
                            title: 'SettingsModule.car-basic-malfunctions',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.CarBasicMalfunctions),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'car-recurrent-malfunctions/:id(\\d+|new)?',
                        name: RouteNamesEnum.CarRecurrentMalfunctions,
                        component: () => import('../views/settings-module/CarRecurrentMalfunctionsView.vue'),
                        meta: {
                            title: 'SettingsModule.car-recurrent-malfunctions',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.CarRecurrentMalfunctions),
                            showInMenu: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default CarsRoutes;
