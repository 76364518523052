<template>
    <v-dialog width="auto" v-model="getShowConfirmDialog" class="dialog" content-class="rounded-0" :class="confirmDialogData.class">
        <v-card content-class="rounded-0">
            <v-toolbar color="primary">
                <v-toolbar-title>{{ confirmDialogData.title || '' }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p v-html="confirmDialogData.text"></p>
            </v-card-text>
            <v-card-actions class="buttons">
                <v-spacer></v-spacer>
                <v-btn @click="close" v-if="confirmDialogData.showCloseBtn">
                    <v-icon icon="mdi-cancel"></v-icon>
                    {{ t('General.cancel') || '' }}
                </v-btn>
                <v-btn @click="confirm">
                    <v-icon :icon="confirmDialogData.icon"></v-icon>
                    {{ confirmDialogData.confirmBtnText || '' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { useGlobalComponentsStore } from "@/stores/global";
import { defineComponent } from 'vue';
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

export default defineComponent({
    name: "ConfirmDialog",
    props: {

    },
    components: {},
    data() {
        return {};
    },
    methods: {
        confirm() {
            let returnData = this.confirmDialogData.return.reduce((acc: {
                [x: string]: any;
            }, key: string | number) => {
                acc[key] = this.confirmDialogData[key];
                return acc;
            }, {});

            // If there is only one return key, return only the value
            if (this.confirmDialogData.return.length === 1) {
                returnData = returnData[this.confirmDialogData.return[0]];
            }

            if (this.confirmDialogData.action) {
                this.$emit('confirm-' + this.confirmDialogData.action, returnData);
            } else {
                this.$emit('confirm', returnData);
            }
            this.close();
        },
        close() {
            this.globalComponentsStore.toggleShowConfirmDialog();
        }
    },
    setup() {
        const globalComponentsStore = useGlobalComponentsStore();
        const { getShowConfirmDialog, getConfirmDialogState} = storeToRefs(globalComponentsStore);
        const { t } = useI18n();
        return {
            globalComponentsStore,
            getShowConfirmDialog,
            confirmDialogData: getConfirmDialogState,
            t,
        };
    }
});
</script>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

.dialog {
    & header {
        background-color: var(--blue) !important;
    }

    & .buttons button {
        background-color: var(--grey-light);
        color: var(--grey-dark);

        &:last-child {
            color: var(--white);
            background-color: var(--blue-dark);
        }

        & i {
            margin-right: 5px;
        }
    }

    &.danger .buttons button:last-child {
        background-color: var(--color-danger);
    }
}
</style>
