import { type DirectiveBinding } from "vue";

const getValueByDottedKey = (obj: any, dottedKey: string) => {
    let item = obj;
    const keys = dottedKey.split('.');
    while (keys.length > 1) {
        const key = keys.shift();
        item = item[key ?? ''];
    }
    return item[keys[0]];
}

export default {
    mounted(el: HTMLElement, { value }: Partial<DirectiveBinding>, vnode: any) {
        el.contentEditable = 'true';
        el.style.backgroundColor = 'rgb(247 247 247)';
        el.style.border = '1px solid #ef8989';

        if (value) {
            el.oninput = function (event) {
                if (event.target) {
                    let item = vnode.ctx.setupState;
                    const keys = value.split('.');
                    while (keys.length > 1) {
                        const key = keys.shift();
                        item = item[key];
                    }

                    if (el.attributes.getNamedItem("data-wrapped-editable")) {
                        item[keys[0]] = el.innerHTML;
                    } else {
                        const cloned =  el.cloneNode(true) as HTMLElement;
                        cloned.attributes.removeNamedItem('contentEditable');
                        cloned.attributes.removeNamedItem('v-content-editable');
                        cloned.attributes.removeNamedItem('style');
                        item[keys[0]] = cloned.outerHTML;
                    }
                }
            };
        }
    },
    updated: function (el: HTMLElement, { value }: Partial<DirectiveBinding>, vnode: any) {
        if (value) {
            const val = getValueByDottedKey(vnode.ctx.setupState, value);

            if (el.attributes.getNamedItem("data-wrapped-editable")) {
                if (val !== el.innerHTML) {
                    el.innerHTML = val;
                }
            } else {
                if (val !== el.outerHTML) {
                    el.outerHTML = val;
                }
            }
        }
    }
};
