import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from "@/composables/usePermissions";
import { ModulesEnum } from "@/enums/ModulesEnum";

const StockRoutes = [
    {
        path: '/stock-items',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'StockModule.name',
            icon: 'mdi-package-variant',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: '',
                name: RouteNamesEnum.Stock,
                component: () => import('../views/vms-module/StockView.vue'),
                meta: {
                    title: 'StockModule.name',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.StockItems),
                    showInMenu: true,
                    isArchive: false,
                },
            },
            {
                path: 'archive',
                name: RouteNamesEnum.StockItemArchive,
                component: () => import('../views/vms-module/StockView.vue'),
                meta: {
                    title: 'StockModule.archive',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.StockItems),
                    showInMenu: true,
                    isArchive: true,
                },
            },
            {
                path: 'stocktaking',
                name: RouteNamesEnum.Stocktaking,
                component: () =>
                    import('../views/vms-module/StocktakingView.vue'),
                meta: {
                    title: 'StockModule.stocktaking',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.StockItems),
                    showInMenu: true,
                },
            },
            {
                path: 'borrowing-stocktaking',
                name: RouteNamesEnum.BorrowingStocktaing,
                component: () =>
                    import('../views/vms-module/StocktakingBorrowingView.vue'),
                meta: {
                    title: 'StockModule.borrowing-stocktaking',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.StockItems),
                    showInMenu: true,
                },
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'stock-item-categories',
                        name: RouteNamesEnum.StockCategories,
                        component: () =>
                            import('../views/vms-module/StockItemCategoriesView.vue'),
                        meta: {
                            title: 'StockModule.stock-categories',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.StockItemCategories),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'producers',
                        name: RouteNamesEnum.Producers,
                        component: () =>
                            import('../views/vms-module/ProducersView.vue'),
                        meta: {
                            title: 'StockModule.producers',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Producers),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'stock-item-locations',
                        name: RouteNamesEnum.Locations,
                        component: () =>
                            import('../views/vms-module/LocationsView.vue'),
                        meta: {
                            title: 'StockModule.locations',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.StockItemLocations),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'units',
                        name: RouteNamesEnum.Units,
                        component: () => import('../views/vms-module/UnitsView.vue'),
                        meta: {
                            title: 'StockModule.units',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Units),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'accounting-categories',
                        name: RouteNamesEnum.AccountingCategories,
                        component: () =>
                            import('../views/vms-module/AccountingCategoriesView.vue'),
                        meta: {
                            title: 'StockModule.accounting-categories',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.AccountingCategories),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'tax-rates',
                        name: RouteNamesEnum.TaxRates,
                        component: () => import('../views/vms-module/TaxRatesView.vue'),
                        meta: {
                            title: 'StockModule.tax-rates',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.TaxRates),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'stock-item-suppliers',
                        name: RouteNamesEnum.Suppliers,
                        component: () =>
                            import('../views/vms-module/SuppliersView.vue'),
                        meta: {
                            title: 'StockModule.suppliers',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.StockItemSuppliers),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'terms-and-conditions',
                        name: RouteNamesEnum.TermsAndContidions,
                        component: () =>
                            import('../views/vms-module/TermsConditionsView.vue'),
                        meta: {
                            title: 'StockModule.terms-and-conditions',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.TermsAndConditions),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'stock-item-registration-number-series/:id(\\d+|new)?',
                        name: RouteNamesEnum.StockItemRegistrationNumberSeries,
                        component: () => import('../views/settings-module/StockItemRegistrationNumberSeriesView.vue'),
                        meta: {
                            title: 'SettingsModule.stock-item-registration-number-series',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.StockItemRegistrationNumberSeries),
                            showInMenu: true,
                        },
                    },
                    {
                        path: 'stock-item-stocktaking-registration-number-series/:id(\\d+|new)?',
                        name: RouteNamesEnum.StockItemStocktakingRegistrationNumberSeries,
                        component: () =>
                            import('../views/settings-module/StockItemStocktakingRegistrationNumberSeriesView.vue'),
                        meta: {
                            title: 'SettingsModule.stock-item-stocktaking-registration-number-series',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.StockItemStocktakingNumberSeries),
                            showInMenu: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default StockRoutes;
