<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useLoginStore } from '@/stores/loginStore';
import { type RouteRecordRaw, useRouter } from 'vue-router';
import { RouteNamesEnum } from "@/enums/RouteNamesEnum";
import { computed } from "vue";
import { useAcl } from "vue-simple-acl";

const { t } = useI18n();
const loginStore = useLoginStore();
const { anyCan } = useAcl();
const router = useRouter();

const hasActiveChild = (route: RouteRecordRaw): boolean => {
    if (route.name === router.currentRoute.value.name) {
        return true;
    }

    return route?.children?.some(
        (child: any) => hasActiveChild(child)
    ) ?? false;
};

const menuItems = computed(() => {
    const menu: RouteRecordRaw[] = [];
    for (const route of router.options.routes) {
        const newRoute = { ...route };

        if (newRoute?.meta?.showInMenu !== true) {
            continue;
        }

        if (newRoute?.meta?.moduleRoot === true) {
            if (newRoute?.children && newRoute.children.length > 0) {
                const children = newRoute.children.filter(
                    (child: any) => child.meta
                        && child.meta.showInMenu === true
                        && (
                            child.meta.canAny === undefined
                            || anyCan(child.meta.canAny)
                        )
                );

                const hasActiveChildren = children.some(
                    (child: any) => hasActiveChild(child)
                );

                if (children.length > 0) {
                    const firstChild = children.shift();
                    if (firstChild) {
                        newRoute.path = firstChild.path;
                        newRoute.name = firstChild.name;

                        for (const child of children) {
                            if (child.children && child.children?.length > 0) {
                                child.name = child.children[0].name;
                            }
                        }

                        newRoute.children = children;
                        newRoute.meta.active = hasActiveChildren;
                        if (newRoute.name !== undefined) {
                            menu.push(newRoute);
                        }
                        continue;
                    }
                }
            }
        }

        if (newRoute?.children && newRoute.children.length > 0) {
            newRoute.children = newRoute.children.filter(
                (child: any) => child.meta
                    && child.meta.showInMenu === true
                    && (
                        child.meta.canAny === undefined
                        || anyCan(child.meta.canAny)
                    )
            );
        }
        if (newRoute.name !== undefined) {
            menu.push(newRoute);
        }
    }
    return menu;
});

const subMenuItems = (routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> => {
    return routes.filter(
        (route) => route.meta
            && route.meta.showInMenu === true
            && (
                route.meta.canAny === undefined
                || anyCan(route?.meta?.canAny as string ?? '')
            )
    );
};
const logout = async () => {
    await loginStore.logout();
};
</script>

<template>
    <header>
        <div class="logo">
            <img
                src="@/assets/img/icons/newe-logo.svg"
                alt="Logo"
            />
        </div>
        <nav>
            <router-link
                v-for="(route, key) in menuItems"
                :to="{ name: route.name }"
                :key="key"
                class="module"
                :class="{ 'router-link-active': route.meta?.active }"
            >
                <v-menu
                    content-class="main-menu-submenu rounded-0"
                    open-on-hover
                    v-if="route.children && subMenuItems(
                                route.children
                            ).length > 0"
                    :transition="false"
                >
                    <template v-slot:activator="{ props }">
                        <p v-bind="props">
                            <i
                                :class="'mdi ' + (route.meta?.icon as string)"
                                class="mr-2"
                            ></i>
                            {{ t(route.meta?.title as string) }}
                        </p>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(subroute, subKey) in route.children"
                            :key="subKey"
                            :to="{name: subroute.name}"
                        >
                            {{ t(subroute.meta?.title as string) }}
                        </v-list-item>
                    </v-list>
                </v-menu>
                <p v-else>
                    <i
                        :class="'mdi ' + (route.meta?.icon as string)"
                        class="mr-2"
                    ></i>
                    {{ t(route.meta?.title as string) }}
                </p>
            </router-link>
        </nav>
        <div class="other-links">
            <router-link to="#">
                <img
                    src="@/assets/img/icons/menu-icons/bell.svg"
                    alt="Notifikace"
                    class="menu-link-icon"
                />
                <v-badge
                    content="2"
                    class="badge"
                />
            </router-link>
            <router-link :to="{name: RouteNamesEnum.Settings}">
                <img
                    src="@/assets/img/icons/menu-icons/settings.svg"
                    alt="Nastavení"
                    class="menu-link-icon"
                />
            </router-link>
        </div>
        <v-menu
            :close-on-content-click="false"
            location="bottom"
            content-class="avatar-dropdown-conent"
        >
            <template v-slot:activator="{ props }">
                <v-card
                    v-bind="props"
                    class="avatar-menu"
                >
                    <v-avatar color="primary">
                        <span class="text-h6">{{loginStore.getUserInitials}}</span>
                    </v-avatar>
                </v-card>
            </template>
            <v-list class="avatar-dropdown">
                <v-list-item
                    :title="loginStore.getUserFullName"
                    :subtitle="loginStore.getUser?.username"
                />
                <router-link
                    :to="{ name: RouteNamesEnum.MyAccount }"
                    class="link-wrapper"
                >
                    <v-list-item>
                        <v-icon
                            icon="mdi-account"
                            size="small"
                        ></v-icon>
                        {{ t('MenuComponent.my-account') }}
                    </v-list-item>
                </router-link>
                <v-list-item @click="logout">
                    <v-icon
                        icon="mdi-logout-variant"
                        size="small"
                    ></v-icon>
                    {{ t('MenuComponent.logout') }}
                </v-list-item>
            </v-list>
        </v-menu>
    </header>
</template>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

header {
    @include display-flex(row, nowrap, space-between, stretch);
    background-color: var(--white);
    color: var(--grey-dark);
    padding-left: 16px;
    padding-right: 16px;
    padding-block: 10px;
    border-bottom: 1px solid var(--grey-light);
    position: relative;
}

nav,
.other-links {
    @include display-flex(row, nowrap, flex-start, stretch);

    & a {
        color: var(--grey-dark);
        text-decoration: none;
        font-weight: var(--font-weight-700);
        @include display-flex(row, nowrap, flex-start, center);
        padding: 0 16px;
        @include transition(all, 250ms, ease-in-out);

        &:hover,
        &.router-link-active {
            color: var(--blue);
            @include transition(all, 250ms, ease-in-out);
        }

        & i,
        & img {
            margin-right: 5px;
        }

        & p {
            height: 100%;
            @include display-flex(row, nowrap, center, center);
        }
    }
}

.other-links {
    margin-left: auto;
    border-left: 1px solid var(--grey-light);

    & a {
        padding: 0 5px;
        margin-left: 10px;
    }

    & .badge :deep(.v-badge__badge) {
        background-color: var(--red);
        font-size: 0.5em;
        min-width: unset;
        width: 12px;
        height: 12px;
        left: unset !important;
        right: -3px;
    }
}

.avatar-menu {
    margin-left: 15px;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    @include userselect;

    & .avatar :deep(.v-avatar) {
        border: 1px solid var(--grey-dark);
        margin-right: 0;
    }
}

.v-menu .avatar-dropdown {
    box-shadow: none !important;
    border-radius: 0 !important;
    border-left: 1px solid var(--grey-light);
    border-bottom: 1px solid var(--grey-light);
    padding: 0;
}

.link-wrapper {
    text-decoration: none;
    color: var(--grey-dark);
    @include transition(all, 250ms, ease-in-out);

    & .v-list-item {
        @include transition(all, 250ms, ease-in-out);
    }

    &:hover .v-list-item {
        background-color: var(--grey-light);
        @include transition(all, 250ms, ease-in-out);
    }

    & i {
        color: var(--grey-dark);
        margin-right: 5px;
    }
}

.logo {
    width: 64px;
    padding-right: 16px;
    border-right: 1px solid var(--grey-light);
    @include display-flex(row, nowrap, center, center);
}
</style>
<style lang="scss">
.avatar-dropdown-conent {
    right: 0;
    left: unset !important;
    top: 60px !important;
}

.main-menu-submenu {
    border-top: 2px solid var(--blue);
}

/* !!! VUETIFY OVERRIDE !!! */
.main-menu-submenu .v-list-item--active > .v-list-item__overlay {
    opacity: 0 !important;
}

.main-menu-submenu .v-list-item:hover > .v-list-item__overlay {
    opacity: calc(
        var(--v-hover-opacity) * var(--v-theme-overlay-multiplier)
    ) !important;
}

.main-menu-submenu .v-list-item--active:hover > .v-list-item__overlay {
    opacity: calc(
        (var(--v-activated-opacity) + var(--v-hover-opacity)) *
        var(--v-theme-overlay-multiplier)
    ) !important;
}
</style>
