export enum dataTableRowActionsEnum {
    ROOT = "root",
    FULL = "full",
    DELETE = "delete",
    DUPLICATE = "duplicate",
    DOWNLOAD = "download",
    ARCHIVE = "archive",
    UNARCHIVE = "unarchive",
    EDIT = "edit",
    DETAIL = "detail",
    CREATE = "create",
    READ = "read",
    TO_ACTIVE = "toActive",
    TO_INACTIVE = "toInactive",
}
