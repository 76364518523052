import type { useConfirmDialogInterface } from "@/interfaces/confirmDialog/useConfirmDialogInterface";
import { useGlobalComponentsStore } from "@/stores/global";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { type Action } from "@/composables/useDataTablePresenter";
import { ref } from "vue";


export interface dataFormConfirmDialog {
    item?: Object;
    callbackAction?: Function;
}

const findNestedValue = (obj: any, key: string): string | null => {
    const keys = key.split('.');
    let value = obj;
    for (const k of keys) {
        if (value && typeof value === 'object' && k in value) {
            value = value[k];
        } else {
            return null;
        }
    }
    return value;
};

export function useConfirmDialog() {
    const { t } = useI18n();
    const globalComponentsStore = useGlobalComponentsStore();
    const { getConfirmDialogState } = storeToRefs(globalComponentsStore);

    return {
        openConfirmDialogWithData(
            data: any,
            callback: Function,
        ) {
            globalComponentsStore.setConfirmDialogState(data);
            globalComponentsStore.setOnConfirmCallback(callback);
            useGlobalComponentsStore().toggleShowConfirmDialog();
        },
        openConfirmDialog: (
            data: Record<string, any>,
            action: Action,
            defaultDialogStateDueToAction: useConfirmDialogInterface,
            nameAttribute?: string
        ) => {
            for (const key in getConfirmDialogState.value) {
                if (key in getConfirmDialogState.value) {
                    getConfirmDialogState.value[
                        key as keyof useConfirmDialogInterface
                        ] =
                        defaultDialogStateDueToAction[
                            key as keyof useConfirmDialogInterface
                            ];
                }
            }
            getConfirmDialogState.value.item = data;
            globalComponentsStore.setOnConfirmCallback(action);

            if (nameAttribute) {
                const name = findNestedValue(data, nameAttribute);
                if (name) {
                    getConfirmDialogState.value.text = t(
                        `ConfirmDialog.${defaultDialogStateDueToAction.translationKey}`,
                        { name }
                    );
                } else {
                    getConfirmDialogState.value.text = '';
                }
            }
            useGlobalComponentsStore().toggleShowConfirmDialog();
        },
        onConfirm: (data: any) => {
            if (globalComponentsStore.getOnConfirmCallback !== null) {
                globalComponentsStore.getOnConfirmCallback(data);
            }
        },
        getConfirmDialogState,
    };
}
