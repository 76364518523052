import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from '@/composables/usePermissions';
import { ModulesEnum } from '@/enums/ModulesEnum';

const SettingsRoutes = [
    {
        path: '/settings',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'SettingsModule.name',
            auth: {
                required: true,
            },
            showInMenu: false,
            moduleRoot: true,
        },
        children: [
            {
                path: '',
                name: RouteNamesEnum.Settings,
                component: () => import('../views/settings-module/SettingsView.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                        roles: [],
                    },
                    showInMenu: true,
                },
            },
            {
                path: 'file-groups',
                name: RouteNamesEnum.FileGrups,
                component: () => import('../views/settings-module/FileGroupsView.vue'),
                meta: {
                    title: 'SettingsModule.file-groups',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.FileGroups),
                    showInMenu: true,
                },
            },
            {
                path: 'users/:id(\\d+|new)?',
                name: RouteNamesEnum.Users,
                component: () => import('../views/users-module/UsersView.vue'),
                meta: {
                    title: 'UsersModule.name',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Users),
                    showInMenu: true,
                }
            },
            {
                path: 'roles-permissions/:id(\\d+|new)?',
                name: RouteNamesEnum.RolesAndPermissions,
                component: () =>
                    import('../views/users-module/RolesAndPermissionsView.vue'),
                meta: {
                    title: 'UsersModule.roles-permissions',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Permissions),
                    showInMenu: true,
                },
            },
            {
                path: 'others',
                name: RouteNamesEnum.Others,
                component: () => import('../views/settings-module/Others.vue'),
                meta: {
                    title: 'SettingsModule.others',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
            },
        ],
    },
];

export default SettingsRoutes;
