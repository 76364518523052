export enum PermissionsEnum {
    StockItemTransferSetAmount = 'stock-item-simple-amount-overwrites-create',
    StockItemTransferBorrow = 'stock-item-renting-batches-perform',
    StockItemTransferReception = 'stock-item-reception-batches-perform',
    StockItemTransferMove = 'stock-item-transfer-batches-perform',
    StockItemTransferRent = 'stock-item-dispense-batches-perform',
    StockItemTransferReturn = 'stock-item-return-batches-perform',
    StockItemTransferCorrection = 'stock-item-stocktaking-correction',

    StockItemContentCreate = 'stock-item-content-items-create',
    StockItemContentUpdate = 'stock-item-content-items-update',
    StockItemContentDelete = 'stock-item-content-items-delete',

    StockItemPurchasesUpdate = 'stock-item-purchases-update',
    StockItemPurchasesDelete = 'stock-item-purchases-delete',

    StockItemCalibrationsCreate = 'stock-item-calibrations-create',
    StockItemCalibrationsUpdate = 'stock-item-calibrations-update',
    StockItemCalibrationsDelete = 'stock-item-calibrations-delete',

    StockItemRevisionsCreate = 'stock-item-revisions-create',
    StockItemRevisionsUpdate = 'stock-item-revisions-update',
    StockItemRevisionsDelete = 'stock-item-revisions-delete',

    StockItemFilesCreate = 'stock-items-create-file',
    StockItemFilesDelete = 'stock-items-delete-file',

    StockItemLogRead = 'stock-items-read-log',
    StockItemContentLogRead = 'stock-item-content-items-read-log',

    StockBatchBorrow = 'stock-item-renting-batches-perform',
    StockBatchReception = 'stock-item-reception-batches-perform',
    StockBatchReturn = 'stock-item-return-batches-perform',
    StockBatchRent = 'stock-item-dispense-batches-perform',
    StockBatchMove = 'stock-item-transfer-batches-perform',

    CarFilesCreate = 'cars-create-file',
    CarFilesDelete = 'cars-delete-file',

    CarChecklistCreate = 'car-basic-malfunctions-create',
    CarChecklistUpdate = 'car-basic-malfunctions-update',
    CarChecklistDelete = 'car-basic-malfunctions-delete',
    CarChecklistComplete = 'car-basic-malfunctions-delete',

    CarRepairCreate = 'car-recurrent-malfunctions-create',
    CarRepairUpdate = 'car-recurrent-malfunctions-update',
    CarRepairDelete = 'car-recurrent-malfunctions-delete',
    CarRepairSendEmail = 'car-repair-checklist-emails-perform',

    CarLogRead = 'cars-read-log',

    UserFiltersGlobalCreate = 'user-filters-create',

    UserColumnsSetGlobalCreate = 'user-column-sets-create',
    UserColumnsSetGlobalUpdate = 'user-column-sets-update',
    UserColumnsSetGlobalDelete = 'user-column-sets-delete',

    ProjectFilesCreate = 'projects-create-file',
    ProjectFilesDelete = 'projects-delete-file',

    InquiriesFilesCreate = 'inquiries-create-file',
    inquiriesilesDelete = 'inquiries-delete-file',

}
