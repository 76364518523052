import axios from 'axios';
import { useLoginStore } from '@/stores/loginStore';

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
});

export const useAxios = () => {
    return axios.create({
        baseURL: import.meta.env.VITE_APP_API_URL,
        headers: useLoginStore().getAuthorizationHeader.headers,
    });
};

export default axiosInstance;
