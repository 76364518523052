export enum AccessibilityStorageKeysEnum {
    FontSize = 'accessibility-font-size',
    ColorContrast = 'accessibility-color-contrast',
}

export const isBiggerFontSize = () => localStorage.getItem(AccessibilityStorageKeysEnum.FontSize) === '1';

export const isContrastColor = () => localStorage.getItem(AccessibilityStorageKeysEnum.ColorContrast) === '1';

export const setBiggerFontSize = (value: boolean) => {
    localStorage.setItem(AccessibilityStorageKeysEnum.FontSize, value ? '1' : '0');
    document.getElementsByTagName('html')[0].classList[value ? 'add' : 'remove']('bigger-font-size');
};

export const setContrastColor = (value: boolean) => {
    localStorage.setItem(AccessibilityStorageKeysEnum.ColorContrast, value ? '1' : '0');
    document.getElementsByTagName('html')[0].classList[value ? 'add' : 'remove']('contrast-color');
}
